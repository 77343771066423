import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { Helmet } from "react-helmet"
import moment from "moment"

const query = graphql`
{
  allStrapiWorks {
    edges {
      node {
        published_at
        strapiId
        name
        description
        siteurl
        image {
          childImageSharp {
            fluid(maxWidth: 700) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        tags {
          name
          id
        }
      }
    }
  }
}
`

function _log(v) {
  console.log(v)
  return v
}

function sortNode(a, b) {
  return Date.parse(b.node.published_at) - Date.parse(a.node.published_at)
}

export default function Home() {
  return (
    <div className="bg-gray-200 pb-64">
      <Helmet
        meta={[
          { name: 'charSet', content: 'utf-8' },
        ]}>
        <title>Portfolio</title>
        <link rel="canonical" href="https://portfolio.acrux.jp/" />
        <html lang="ja" />
      </Helmet>
      <StaticQuery
          query={query}
          render={data => (
        <div>

          <div className="mx-2">
            <h1 className="font-bold text-3xl text-gray-800">Portfolio</h1>

            <div className="mt-4 md:mx-2 flex flex-wrap">
              {data.allStrapiWorks.edges
              .sort(sortNode)
              .filter(work => work.node.tags.find(v => v.id === "5fc4cfa4f99410c165844c0e")) // portfolio
              .map(work => (
                viewItem(work)
              ))}
            </div>
          </div>
          <div className="mt-32 mx-2 border-t">
            <h3 className="font-bold text-3xl text-gray-800">All Works</h3>

            <div className="mt-4 md:mx-2 flex flex-wrap">
              {data.allStrapiWorks.edges
              .sort(sortNode)
              .map(work => (
                viewItem(work)
              ))}
            </div>
          </div>
        </div>

          )}
      />
      <div className="mt-16 mx-2 border-t">
        <h3 className="mt-4 font-bold text-3xl text-gray-800">Link</h3>
        <div className="mt-4 ml-4">
          <a href="https://www.wantedly.com/users/126188181" target="wantedly|126188181">Profile - Wantedly</a>
        </div>
      </div>
    </div>
  )
}


function viewItem(work) {
  return <div
    key={work.node.strapiId}
    className="mt-6 w-full md:w-1/2 lg:w-1/3"
  >
    <a
      href={work.node.siteurl}
      target={work.node.strapiId}
      >

      <div
        className="md:mx-2 rounded-lg border shadow-lg overflow-hidden bg-gray-100"
      >
  {work.node.image &&
        <Img
          fluid={work.node.image.childImageSharp.fluid}
          className="h-32 p-1 border-b-2"
        />
  }

        <div className="p-6">
          <div className="text-2xl text-gray-800">
            {work.node.name}
          </div>
          <div className="text-sm text-gray-500">
            {moment(work.node.published_at).format('YYYY-MM-DD')}
          </div>
          <div className="mt-4 text-gray-700">
            {work.node.description}
          </div>

          <div class="mt-4 font-bold text-lg text-gray-700">
            コードを見る
          </div>
        </div>
      </div>
    </a>
  </div>
}